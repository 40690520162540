<template>
  <div class="home">
    <!-- <div class="logo">
      <img src="../../public/images/hirschhuber.png">
    </div> -->
    <div class="projects-container">
      <div class="projects not-mobile">
        <div class="project-container">
          <div class="project">
            <a href="https://www.ar-technology.at" target="_blank">
              <img src="../../public/images/AR_Mixed_TECHNOLOGY_ohneZusatz_1C_B_RGB.png">
            </a>
          </div>
        </div>
        <div class="project-container">
          <div class="project">
            <a href="https://www.mxrtactics.com/" target="_blank">
              <img src="../../public/images/MXR_4c_CMYK.png">
            </a>
          </div>
        </div>
        <div class="project-container">
          <div class="project">
            <a href="https://www.xr-gaming.at/" target="_blank">
              <img src="../../public/images/xr-gaming.svg">
            </a>
          </div>
        </div>
      </div>
      <div class="projects not-mobile">
        <div class="project-container">
          <div class="project">
            <a href="https://www.rice8fries.com" target="_blank">
              <img src="../../public/images/R8F_Logo_Underline_1c_B_RGB.png">
            </a>
          </div>
        </div>
        <div class="project-container">
          <div class="project">
            <a href="https://www.craftlane.io" target="_blank">
              <img src="../../public/images/craftlane-logo.svg">
            </a>
          </div>
        </div>
        <div class="project-container">
          <div class="project">
            <a href="https://www.fanclip.eu/de" target="_blank">
              <img src="../../public/images/fanclip.png">
            </a>
          </div>
        </div>
      </div>
      <div class="projects not-mobile">
        <div class="project-container">
          <div class="project">
            <a href="https://supermobility.at" target="_blank">
              <img src="../../public/images/Super_Mobility_2-zeilig_Logo_s.png">
            </a>
          </div>
        </div>
        <div class="project-container">
          <div class="project">
            <a href="https://superrent.at" target="_blank">
              <img src="../../public/images/Super_Rent_2-zeilig_Logo_s.png">
            </a>
          </div>
        </div>
        <div class="project-container">
          <div class="project">
            <a href="https://www.superstrom.at" target="_blank">
              <img src="../../public/images/SuperStrom_Logo_4c_RGB.png">
            </a>
          </div>
        </div>
      </div>
      <div class="projects not-mobile">
      </div>
    </div>
    <div class="menu">
      <button @click="openBlog()">Blog</button>
    </div>
    <div class="contact">
      <div class="contact-container">
        <div class="contact-inner-container">
          <div class="contact-social">
            <a href="https://at.linkedin.com/in/richard-hirschhuber-3a072872" target="_blank">
              <img src="../../public/images/linked_in.png">
            </a>
          </div>
          <div class="contact-social">
            <a href="https://www.facebook.com/richard.hirschhuber" target="_blank">
              <img src="../../public/images/facebook.png">
            </a>
          </div>
          <div class="contact-social">
            <a href="https://twitter.com/richardh888" target="_blank">
              <img src="../../public/images/twitter.png">
            </a>
          </div>
          <div class="contact-text">
            Richard Hirschhuber &bull;
            <a href="mailto:richard@hirschhuber.com">richard@hirschhuber.com</a> &bull;
            <a href="tel:+436643412121">+43 664 34 12 121</a>
          </div>
        </div>
        <div class="contact-inner-container">
          <div class="contact-social">
            <a href="https://www.linkedin.com/in/robert-hirschhuber-a10a5a141" target="_blank">
              <img src="../../public/images/linked_in.png">
            </a>
          </div>
          <div class="contact-social">
          </div>
          <div class="contact-social">
          </div>
          <div class="contact-text">
            Robert Hirschhuber &bull;
            <a href="mailto:robert@hirschhuber.com">robert@hirschhuber.com</a> &bull;
            <a href="tel:+436644111464">+43 664 41 11 464</a>
          </div>
        </div>
      </div>
    </div>
    <Cookies/>
  </div>
</template>

<script>
import Cookies from '../components/Cookies';
export default {
  name: 'HomePage',
  components: {
    Cookies
  },
  methods: {
    openBlog () {
      window.open('https://blog.hirschhuber.com/','_blank');
    }
  }
}
</script>

<style lang="scss">
  @import "../assets/scss/layout.scss";
  .home {
    padding: 180px 100px;
    text-align: center;
    background: url("../../public/images/background/Background_2560.jpg") no-repeat center;
    background-size: cover;
    @include MQ(L) {
      padding-top: 100px;
      background: url("../../public/images/background/Background_1440.jpg") no-repeat center;
    }
    @include MQ(M) {
      padding: 75px 25px 80px;
      background: url("../../public/images/background/Background_1440.jpg") no-repeat center;
    }
    @include MQ(S) {
      padding: 80px 50px;
      background: url("../../public/images/background/Background_1440.jpg") no-repeat center;
    }
    @include MQ(XS) {
      padding: 40px 20px;
      background: url("../../public/images/background/Background_1024.jpg") no-repeat center;
    }
    @include MQ(XXS) {
      padding: 40px 20px;
      background: url("../../public/images/background/Background_1024.jpg") no-repeat center;
    }
    .menu {
      padding-top: 45px;
      padding-bottom: 25px;
      @include MQ(XS) {
        padding-top: 30px;
        padding-bottom: 10px;
      }
      @include MQ(XXS) {
        padding-top: 30px;
        padding-bottom: 10px;
      }
      button {
        background: #FFFFFF 0 0 no-repeat padding-box;
        box-shadow: 0 3px 6px #00000029;
        border: none;
        padding: 8px 45px;
        font-size: 20px;
        font-weight: 600;
        border-radius: 25px;
        cursor: pointer;
      }
    }
    .logo {
      text-align: center;
      margin-bottom: 125px;
      @include MQ(L) {
        margin-bottom: 150px;
      }
      @include MQ(M) {
        margin-bottom: 175px;
      }
      @include MQ(S) {
        margin-bottom: 150px;
      }
      @include MQ(XS) {
        padding: 25px 25px;
        margin-bottom: 50px;
      }
      @include MQ(XXS) {
        padding: 25px 10px;
        margin-bottom: 50px;
      }
    }
    .projects-container {
      display: inline-block;
      .projects {
        display: flex;
        justify-content: left;
        .project-container {
          display: inline-block;
          .project {
            width: 150px;
            height: 150px;
            background: #FFFFFF 0 0 no-repeat padding-box;
            box-shadow: 0 3px 6px #00000029;
            border-radius: 25px;
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-bottom: 50px;
            @include MQ(M) {
              width: 130px;
              height: 130px;
              margin-bottom: 30px;
            }
            @include MQ(L) {
              width: 135px;
              height: 135px;
              margin-bottom: 40px;
            }
            @include MQ(S) {
              width: 120px;
              height: 120px;
            }
            @include MQ(XS) {
              width: 100px;
              height: 100px;
              margin-bottom: 20px;
            }
            @include MQ(XXS) {
              width: 80px;
              height: 80px;
              margin-bottom: 10px;
            }
            img {
              width: 104px;
              height: 62px;
              object-fit: contain;
              @include MQ(S) {
                width: 90px;
                height: 62px;
              }
              @include MQ(XS) {
                width: 80px;
              }
              @include MQ(XXS) {
                width: 70px;
              }
            }
          }
          &:not(:last-child) {
            margin-right: 50px;
            @include MQ(L) {
              margin-right: 40px;
            }
            @include MQ(M) {
              margin-right: 30px;
            }
            @include MQ(XS) {
              margin-right: 20px;
            }
            @include MQ(XXS) {
              margin-right: 10px;
            }
          }
        }
      }
    }
    .contact {
      margin-top: 125px;
      text-align: center;
      @include MQ(M) {
        margin-top: 250px;
      }
      @include MQ(S) {
        margin-top: 200px;
      }
      @include MQ(XS) {
        padding: 0;
        margin-top: 50px;
      }
      @include MQ(XXS) {
        padding: 0;
        margin-top: 50px;
      }
      .contact-container {
        display: inline-block;
        text-align: left;
        .contact-inner-container {
          display: flex;
          margin-bottom: 15px;
          @include MQ(XXS) {
            display: inline-block;
          }
          .contact-social {
            display: inline-block;
            margin-right: 15px;
            width: 30px;
            height: 30px;
            min-width: 30px;
            @include MQ(M) {
              margin-right: 10px;
            }
            @include MQ(S) {
              margin-right: 10px;
            }
            @include MQ(XS) {
              margin-right: 10px;
            }
          }
          .contact-text {
            display: inline-block;
            color: white;
            font-size: 25px;
            font-weight: 300;
            @include MQ(S) {
              font-size: 18px;
            }
            @include MQ(XS) {
              font-size: 18px;
            }
            @include MQ(XXS) {
              font-size: 18px;
              display: block;
            }
            a {
              display: inline-block;
              cursor: pointer;
              text-decoration: none;
              color: white;
            }
          }
          a {
            display: inline-flex;
            align-items: center;
            cursor: pointer;
            text-decoration: none;
            img {
              height: 30px;
              width: 30px;
            }
          }
        }
      }
    }
  }
</style>
